.App {
  text-align: center;
  height: 100%;
}



.RaffleCard {
  color: "white";
}


body {
  background: linear-gradient(
    to left,
    black,
    #000221 40%,
    #000221 60%,
    black 100%
  );
  background-color: black;
  color: white;
}

.hoverable-card {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.4) 10%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(0, 0, 0, 0.6) 80%
  );
  transition: background 0.4s linear;
  cursor: pointer;
}
